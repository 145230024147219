<script setup>
defineProps({
    content: {
        type: Object,
        required: true,
    },
});
const { data: services, pending } = await useApiFetch(`/api/service-home`, { lazy: true });
</script>

<template>
    <div v-if="!pending" class="relative pt-36 pb-12 light-bg">
        <div class="container px-8">
            <div class="max-w-3xl text-center mx-auto mb-14 space-y-6">
                <h1 v-if="content?.title" class="text-primary text-xl text-base font-bold">{{ content?.title }}</h1>
                <p class="text-text-primary text-4xl">
                    {{ content?.description }}
                </p>
            </div>
            <div v-if="services.data.length" class="px-8 grid lg:grid-cols-4 gap-6 sm:grid-cols-2 grid-cols-1">
                <template v-for="service in services.data" :key="service.id">
                    <NuxtLink href="/services">
                        <div class="group box-borders bg-white py-8 px-4 rounded-md shadow-sm hover:bg-white hover:scale-105 ease-in-out duration-300 h-full">
                            <div class="flex mx-auto">
                                <div class="rounded-full bg-dark group-hover:bg-primary p-4 mx-auto ease-in-out duration-300">
                                    <ServicesIcons :icon-name="service.subTitle" color="text-light" icon-class="w-14 h-14 flex-shrink-0 mx-auto" />
                                </div>
                            </div>
                            <div class="my-4 text-center">
                                <h1 class="text-2xl font-bold text-primary">{{ service.name }}</h1>
                                <p class="text-text-secondary line-clamp-3">{{ service.description }}</p>
                            </div>
                            <div class="text-center text-base group-hover:text-primary ease-in-out font-bold duration-300">Read More...</div>
                        </div>
                    </NuxtLink>
                </template>
            </div>
        </div>
    </div>
</template>
